import { useQuery, ApolloError } from "@apollo/client"
import React, { createContext, useContext, ReactNode } from "react"
import { WP_GET_USER } from "../graphql/mutations"

export interface User {
  id: string
  databaseId: number
  firstName: string
  lastName: string
  email: string
  capabilities: string[]
}

interface AuthData {
  loggedIn: boolean
  user?: User
  loading: boolean
  error?: ApolloError
  showAdminBar: boolean
}

const DEFAULT_STATE: AuthData = {
  loggedIn: false,
  user: undefined,
  loading: false,
  error: undefined,
  showAdminBar: false
}

const AuthContext = createContext(DEFAULT_STATE)

export function AuthProvider({ children }: { children: ReactNode }) {
  const { data, loading, error } = useQuery(WP_GET_USER)

  const user = data?.viewer
  const loggedIn = Boolean(user)

  const showAdminBar = loggedIn && user?.capabilities?.includes("edit_pages")

  const value = {
    loggedIn,
    user,
    loading,
    error,
    showAdminBar
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

export default useAuth
