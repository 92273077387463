import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

let uri = `${process.env.NEXT_PUBLIC_API_URL}/wp/graphql`

const httpLink = createHttpLink({ uri, credentials: "include" })

const authLink = setContext((_, { headers }) => {
  headers
})

let client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache"
    }
  },
  credentials: "include"
})

export { client }
