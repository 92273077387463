// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://15a8e67d679d4d32b2da607ecd1620f3@o1157064.ingest.sentry.io/6239069",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  enabled: false,
  tunnel: "/api/tunnel/"
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
