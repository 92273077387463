import { ApolloProvider } from "@apollo/client"
import { client } from "@/lib/graphql/client"
import { AuthProvider } from "@/lib/hooks/useAuth"
import GlobalStyle from "@/styledComponents/global"
import type { AppProps } from "next/app"
import "../styles/app.css"

import { init, push } from "@socialgouv/matomo-next"
import { useEffect, useRef } from "react"

const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL
const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID

declare global {
  interface Window {
    Cookiebot: {
      consent: {
        statistics: boolean
        marketing: boolean
      }
    }
  }
}

function App({ Component, pageProps }: AppProps) {
  const hasInit = useRef(false)
  useEffect(() => {
    if (hasInit.current) {
      return
    }
    init({ url: MATOMO_URL || "", siteId: MATOMO_SITE_ID || "" })
    push(["requireCookieConsent"])
    hasInit.current = true

    window.addEventListener("CookiebotOnDecline", () => {
      statisticsConsentSet()
    })

    window.addEventListener("CookiebotOnAccept", () => {
      statisticsConsentSet()
    })
  }, [])

  const statisticsConsentSet = () => {
    if (window.Cookiebot.consent.statistics) {
      push(["rememberCookieConsentGiven"])
      return
    }

    push(["forgetCookieConsentGiven"])
  }

  return (
    <>
      <GlobalStyle />
      <div className="flex flex-col flex-grow min-h-screen">
        <ApolloProvider client={client}>
          <AuthProvider>
            <Component {...pageProps} />
          </AuthProvider>
        </ApolloProvider>
      </div>
    </>
  )
}

export default App
