import { gql } from "@apollo/client"

export const WP_GET_USER = gql`
  query getUser {
    viewer {
      id
      databaseId
      firstName
      lastName
      email
      capabilities
    }
  }
`

export const WP_LOG_IN = gql`
  mutation logIn($login: String!, $password: String!) {
    loginWithCookies(input: { login: $login, password: $password }) {
      status
    }
  }
`

export const WP_LOG_OUT = gql`
  mutation logOut {
    logout(input: {}) {
      status
    }
  }
`

export const WP_REGISER_USER = gql`
  mutation RegisterUser(
    $username: String!
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    registerUser(
      input: {
        username: $username
        email: $email
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      clientMutationId
      user {
        userId
      }
    }
  }
`

export const WP_SEND_PASSWORD_RESET_EMAIL = gql`
  mutation SendPasswordResetEmail($username: String!) {
    sendPasswordResetEmail(input: { username: $username }) {
      user {
        id
        email
      }
    }
  }
`

export const WP_RESET_USER_PASSWORD = gql`
  mutation ResetUserPassword(
    $key: String!
    $username: String!
    $password: String!
  ) {
    resetUserPassword(
      input: { key: $key, login: $username, password: $password }
    ) {
      user {
        id
      }
    }
  }
`
