import { createGlobalStyle } from "styled-components"
import tw from "twin.macro"

const GlobalStyle = createGlobalStyle`
  /* Component spacing */
  #app section.component.bg-white + .bg-white,
  #app section.component.bg-gray + .bg-gray,
  #app section.component.bg-blue + .bg-blue {
    ${tw`pt-0`};
  }

  /* Typography */
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    ${tw`font-bold break-words`}
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }

  h1,
  .h1 {
    ${tw`text-[2rem] leading-9 sm:text-4xl sm:leading-[2.75rem] md:text-[2.5rem] md:leading-[3rem] lg:text-[2.75rem] lg:leading-[3.25rem] xl:text-5xl xl:leading-[3.5rem]`};
  }

  h2,
  .h2 {
    ${tw`text-2xl sm:text-[1.75rem] sm:leading-9 md:text-[2rem] md:leading-[2.5rem] lg:text-4xl lg:leading-[2.75rem] xl:text-[2.5rem] xl:leading-[3rem]`};
  }

  h3,
  .h3 {
    ${tw`text-[1.375rem] leading-7 sm:text-2xl sm:leading-[1.75rem] lg:leading-8 xl:text-[1.75rem] xl:leading-9`};
  }

  h4,
  .h4 {
    ${tw`text-xl xl:text-2xl`};
  }

  h5,
  .h5 {
    ${tw`text-lg leading-[1.5rem] xl:text-xl xl:leading-[1.625rem]`};
  }

  /* Buttons */
  .btn {
    ${tw`block sm:inline-flex transition duration-200 font-bold py-3.5 px-6 text-base text-center items-center justify-center !no-underline break-words`}

    & + .btn {
      ${tw`ml-2`}
    }
  }

  .btn__icon {
    ${tw`w-6 h-6`}
  }

  .btn-yellow {
    ${tw`text-blue-dark bg-yellow hover:bg-yellow-400 active:bg-yellow-500`}

    &.btn--active {
      ${tw`active:bg-yellow`}
    }
  }

  .btn-blue {
    ${tw`text-white bg-blue hover:bg-blue-600 active:bg-blue-dark`}
  }

  .btn-lightblue {
    ${tw`bg-blue-200 text-blue-dark hover:bg-blue-100 active:bg-blue-300`}
  }

  .btn-link {
    ${tw`text-blue text-left justify-start hover:text-blue-600 font-semibold active:text-blue-dark relative p-0 text-base border-0 !underline`}
  }

  .btn-lightlink {
    ${tw`text-white hover:text-blue-400 active:text-blue-500 relative p-0 text-base border-0 !underline`}
  }

  /* Modifiers */
  .btn--small {
    ${tw`px-3 py-[0.375rem] text-sm rounded`}
  }

  .btn--icon {
    ${tw`px-4`}
  }

  .btn--icon-center {
    ${tw`px-[0.938rem] py-[0.938rem] rounded-md`}

    &.btn--rounded {
      ${tw`px-[0.938rem] rounded-full`}
    }
  }
`

export default GlobalStyle
